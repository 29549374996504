import { useEffect, useState } from 'react'
import logging from 'shared/utils/logging'
import swoopFetch from 'web-client/utils/swoopFetch'
import SwoopHeaders from 'web-client/utils/swoopHeaders'
import { useMountedState } from 'react-use'

type PromiseResolvers<T> = {
  resolve: (value?: T | PromiseLike<T>) => void
  reject: (reason?: any) => void
}
// Previously fetched environment variables
export const _envCache: Record<string, any> = {}
// Environment variables we are currently fetching
const envRequesting: Record<string, Promise<any>> = {}
// Resolvers associated with env var fetches
const envResolvers: Record<string, PromiseResolvers<any>> = {}
let timeout: NodeJS.Timeout | null = null

const requestEnvVar = async (envVar: string) => {
  const response = await swoopFetch(
    `/api/envs?${new URLSearchParams({ requested_variables: envVar })}`,
    {
      headers: SwoopHeaders.headers,
    }
  )
  if (response.ok) {
    try {
      return await response.json()
    } catch (e: any) {
      const message = 'Error parsing JSON'
      logging.logWarning(message, {
        error: {
          code: e.code || e.error,
          message: e.message,
          stack: e.stack,
        },
      })
      const thrownError = `${message} ${e.message}`
      throw Error(thrownError)
    }
  } else {
    throw Error(await response.text())
  }
}

const makeRequest = async (variables: string[], attempts = 0, interval = 1000) => {
  try {
    const requestedVariables = variables.join(',')
    const data = (await requestEnvVar(variables.join(','))) as Record<string, any>
    try {
      const keys = Object.keys(data)
      keys.forEach((key) => {
        if (envResolvers[key]) {
          envResolvers[key].resolve(data[key])
        } else {
          logging.logWarning('Failed to find resolver for env response', {
            key,
            requestedVariables,
            data,
          })
        }
        delete envResolvers[key]
        delete envRequesting[key]
      })
    } catch (e: any) {
      logging.logWarning('Failed to parse env response', {
        requestedVariables,
        error: e?.message,
        data,
      })
      throw e
    }
  } catch (error) {
    if (attempts < 5) {
      setTimeout(() => {
        void makeRequest(variables, attempts + 1, interval ** 1.1)
      }, interval)
    } else {
      variables.forEach((key) => {
        try {
          envResolvers[key].reject(error)
        } catch (e) {
          logging.logWarning('Attempted to reject invalid key', { key })
        }
        delete envResolvers[key]
        delete envRequesting[key]
      })
      logging.logWarning(
        `getEnvironmentVariable: Problem loading environment variable: ${variables.join(
          ','
        )}, Error: ${JSON.stringify(error)}`
      )
    }
  }
}

const getEnvironmentVariableOrPromise = (variable: string) => {
  if (typeof envRequesting[variable] !== 'undefined') {
    // Currently making a request, returns associated promise
    return envRequesting[variable]
  } else {
    envRequesting[variable] = new Promise((resolve, reject) => {
      // Save resolvers to call later, when response is received
      envResolvers[variable] = { resolve, reject }
    })
    // Creates a new request after a timeout for variable if it doesn't exist
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = null
        void makeRequest(Object.keys(envRequesting))
      }, 1000)
    }
    return envRequesting[variable]
  }
}

// For olden, class-based components
const getEnvironmentVariable = async (props: string | string[]) => {
  const variableArray = Array.isArray(props) ? props : [props]
  const results = await Promise.all(
    variableArray.map((variable) => {
      if (_envCache[variable]) {
        return _envCache[variable]
      } else {
        return getEnvironmentVariableOrPromise(variable)
      }
    })
  )
  return variableArray.reduce((mapping, obj, index) => {
    _envCache[obj] = results[index]
    return {
      ...mapping,
      [obj]: results[index],
    }
  }, {})
}

// For new hotness hooks-based components
const useEnvironmentVariable = <T extends Record<string, unknown>>(
  variable: string | string[]
): [T, boolean] => {
  const [envVar, setEnvVar] = useState<T>({} as T)
  const [isLoading, setIsLoading] = useState(true)
  const isMounted = useMountedState()

  useEffect(() => {
    setIsLoading(true)
    const runEffect = async () => {
      try {
        const ret = await getEnvironmentVariable(variable)
        if (isMounted()) {
          setEnvVar(ret as T)
        }
      } catch (e) {
        // sent error to datadog above
      } finally {
        if (isMounted()) {
          setIsLoading(false)
        }
      }
    }
    void runEffect()
  }, [isMounted, variable])

  return [envVar, isLoading]
}

export { getEnvironmentVariable, useEnvironmentVariable }
